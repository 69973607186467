<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Puntos / <strong class="text-primary small">Nuevo Punto</strong></h3>
                </div>
                <div class="nk-block-head-content">
                  <router-link to="/sistema/puntos" replace class="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em class="icon ni ni-arrow-left"></em><span>Regresar</span></router-link>
                  <router-link to="/sistema/puntos" replace class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em class="icon ni ni-arrow-left"></em></router-link>
                </div>
              </div>
            </div><!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <form @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">Nuevo Punto</h5>
                          </div><!-- .nk-block-head -->
                          <div class="form-group">
                            <label class="form-label">Nombre <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.nombre" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Descripción</label>
                            <textarea class="form-control" v-model="model.descripcion"></textarea>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Ubicación</label>
                            <div class="row q-gutter-lg q-py-md">
                              <div>
                                <label class="form-label">Latitud <span class="ml-1 text-danger">*</span></label>
                                <input type="number" step="0.00000001" class="form-control" autocomplete="nope" v-model.lazy="model.position.lat" required>
                              </div>
                              <div>
                                <label class="form-label">Longitud <span class="ml-1 text-danger">*</span></label>
                                <input type="number" step="0.00000001" class="form-control" autocomplete="nope" v-model.lazy="model.position.lng" required>
                              </div>
                            </div>
                            <Mapa v-model="model.position"></Mapa>
                          </div>
                          <div class="w-100 text-right">
                            <button type="submit" class="mt-2 btn btn-lg btn-primary">Guardar Punto</button>
                          </div>
                        </div><!-- .nk-block -->
                      </div><!-- .card-inner -->
                    </form>
                  </div><!-- .card-content -->
                </div><!-- .card-aside-wrap -->
              </div><!-- .card -->
            </div><!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import {defineComponent, ref} from 'vue';
import {useApi} from "@/use/useApi";
import {useRouter} from "vue-router";
import Swal from "sweetalert2";
import Mapa from "@/components/globales/Mapa.vue";
export default defineComponent({
  name: "Nuevo Punto",
  components: {Mapa, AppView},
  setup() {
    const { loading, make } = useApi();

    const router = useRouter();

    const model = ref({
      nombre: '',
      descripcion: '',
      id_servicio: '',
      position: {
        lat: null,
        lng: null
      }
    });

    async function save(){
      loading.message = 'Guardando punto';
      let {data} = await make('puntos/new', {model: model.value});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', data.message, 'success').then(() => {
            router.push('/sistema/puntos');
          });
        }
      }
    }

    return {model, save};
  }
});
</script>

<style scoped>

</style>
